<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-03-10 17:25:20
 */
import ltreeitem from './ltree_item.vue'
import ltreeall from './ltree_all.vue'

export default {
  components: {ltreeitem, ltreeall},
  props: {
    w: {
      type: String,
      default: '400px'
    },
    // 是否有全部
    all: {
      type: String,
      default: ''
    },
    // 数据
    data: {
      type: Array,
      default: []
    },
    // 菜单按钮
    menu: {
      type: Array,
      default: []
    },
    // 菜单按钮
    all_menu: {
      type: Array,
      default: []
    },
    // 文件夹类型
    dt: {
      type: Number,
      default: 0
    },
    // 内容类型
    it: {
      type: Number,
      default: 1
    },
    // 打开类型 all-不限制 only-只能打开一个
    ot: {
      type: String,
      default: 'all'
    },
    // 打开类型 all-不限制 only-只能打开一个
    callback: {
      type: Function,
      default: () => {
      }
    },
    renameDo: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      active_arr: [],
      rename_active: '',
    }
  },
  mounted() {
  },
  methods: {
    renameActive(id) {
      this.rename_active = id
    },
    openGroup(arr) {
      this.active_arr = arr
    },
    allClick() {
      this.active_arr = ['l_all']
    },
    feedback(arr) {
      this.active_arr = arr.map((item) => {
        return item.id
      })
      this.callback(arr)
    }
  }
}
</script>
<template>
  <div>
    <div :style="{
      width:w
    }" class="ltree_wrapper">
      <ltreeall v-if="all === 'all'" :w="w" :active="active_arr" :menu="all_menu" :feedback="feedback"></ltreeall>
      <ltreeitem :ot="ot" :w="w" :dt="dt" :menu="menu" :rename_active="rename_active" :active="active_arr" :it="it"
                 :info="gi" v-for="(gi,gk) in data" :renameDo="renameDo" :feedback="feedback"></ltreeitem>
    </div>
  </div>
</template>
<style>
* {
  /*background: #00000010;*/
}
</style>
<style scoped>
.all_active {
  background: #c9e3ff;
}

.group_name_wrapper {
  user-select: none;
}

.item_icon_wrapper {
  font-size: 16px;
}

.dir_open_icon_wrapper {
  font-size: 12px;
  opacity: 0;
}

.all_wrapper {
  height: 40px;
  line-height: 40px;
}

.ltree_wrapper {
  position: absolute;
  top: 55px;
  bottom: 0;
  left: 0px;
  right: 0;
  width: 100%;
  z-index: 99999999;
  padding-left: 5px;
}

.group_name_wrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
