<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-04-03 10:22:49
 */
export default {
  props: ['info', 'close'],
  data() {
    return {
      checkmarklist: [],
      checkmarklistchoose: [],
      checkbiaobenlistchoose: [],
      checkmarklistselect: [],
      check_huanjing: [],
      item_yiqi_list: [],
      checkbiaobenlistselect: [],
      checkpushvaluelist: [],
      jcitemyiqi_choose: [],
      jcitemyiqi_list: [],
      tags: [],
      tag_input: '',
      tag_input_show: false,
      task_check_info: false,
      visible: true,
      check_date: '',
      check_start_date: '',
      check_end_date: '',
      check_wd: '',
      check_sd: '',
      check_qy: '',
      push_value: '',
      push_area: false,
      checkpushvaluecount: 0,
      check_data_value: 0,
      iframe_show: false,
      auto_check: false,
      backdata_list: [],
      gongshi_image: [],
      jcitemhuanjing_list: [],
      url: '/cclucky.html',
      table_path: '',
      url_p: {
        x: 5,
        y: 5,
        t: 1,
        i: 0,
      },
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    mountedDo() {

      // this.jcitemyiqi_choose = [53, 54, 55]
      // this.check_date = "2022-04-03"
      // this.tags = ['YP_00001', 'YP_00002']

      this.gettaskcheckinfo()


    },

    getjjianceitemyiqilist() {
      // 检测项目：检测项目仪器列表
      this.$sa0.post({
        url: this.$api('检测项目：检测项目仪器列表'),
        data: {
          jc_item_id: this.task_check_info.check_item,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.item_yiqi_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    getcheckiteminfo() {
      // check_item
    },
    async getgongshivalueajax(gongshi, valuearr) {
      return await this.$sa0.post({
        url: this.$api('公式：公式计算'),
        data: {gongshi, valuearr}
      }).then((response) => {
        return response.data.data.value
      })
    },
    pushvalue() {
      this.gongshi_image = [];
      this.backdata_list.map(async (bi) => {
        let value_info = false
        let value = false
        if (bi.range) {
          let range = JSON.parse(bi.range)
          if (bi.val_type === 1) {
            // console.log('bi', )
            // 公式
            let cont = JSON.parse(bi.gongshi_data)
            // console.log(cont)
            let varr = [];
            cont.map((item) => {
              if (item.type === 1) {
                // 仪器输出值
                this.checkpushvaluelist.map((yp_group) => {
                  yp_group.list.map((xuhao_group) => {
                    xuhao_group.list.map((ci) => {
                      if (bi.val === ci.mark_yiqi) {
                        if (bi.back_index === xuhao_group.mark_bh) {
                          item.pv = ci.value
                          varr.push(item)
                        }
                      }
                    })
                  })
                })
              }
              if (item.type === 2) {
                // 工作条件
                this.checkpushvaluelist.map((yp_group) => {
                  yp_group.list.map((xuhao_group) => {
                    xuhao_group.list.map((ci) => {
                      if (bi.val === ci.mark_yiqi) {
                        if (bi.back_index === xuhao_group.mark_bh) {
                          item.pv = ci.value
                          varr.push(item)
                        }
                      }
                    })
                  })
                })
              }
              if (item.type === 3) {
                // 数值
                item.pv = item.value
                varr.push(item)
              }
              if (item.type === 4) {
                // 表格位置
                let rr = JSON.parse(item.value)
                item.pv = document.getElementById('iframeid').contentWindow.app.getCellValue(rr.row[0], rr.column[0]);
                item.pv = item.pv || 0
                varr.push(item)
              }
            })
            value = await this.getgongshivalueajax(bi, varr)
            value = this.valueround(value, bi)
            document.getElementById('iframeid').contentWindow.app.setCellValue(value, range.row[0], range.column[0]);
          }
          if (bi.val_type === 2) {
            // 公式图例
            this.gongshi_image.push({
              range: range,
              id: bi.val,
            })
            document.getElementById('iframeid').contentWindow.app.setCellValue(' ', range.row[0], range.column[0]);
          }
          if (bi.val_type === 3) {
            // 仪器输出
            this.checkpushvaluelist.map((yp_group) => {
              yp_group.list.map((xuhao_group) => {
                xuhao_group.list.map((ci) => {
                  if (bi.val === ci.mark_yiqi) {
                    if (bi.back_index === xuhao_group.mark_bh) {
                      value_info = {
                        ci,
                        bi
                      }
                    }
                  }
                })
              })
            })
            if (value_info) {
              value = this.valueround(value_info.ci.value, bi)
              document.getElementById('iframeid').contentWindow.app.setCellValue(value, range.row[0], range.column[0]);
            }
          }
          if (bi.val_type === 4) {
            // 工作条件
            this.checkpushvaluelist.map((yp_group) => {
              yp_group.list.map((xuhao_group) => {
                xuhao_group.list.map((ci) => {
                  if (bi.val === ci.mark_yiqi) {
                    if (bi.back_index === xuhao_group.mark_bh) {
                      value_info = {
                        ci,
                        bi
                      }
                    }
                  }
                })
              })
            })
            if (value_info) {
              value = this.valueround(value_info.ci.value, bi)
              document.getElementById('iframeid').contentWindow.app.setCellValue(value, range.row[0], range.column[0]);
            }
          }
          if (bi.val_type === 5) {
            // 固定值
            value = this.valueround(bi.val, bi)
            document.getElementById('iframeid').contentWindow.app.setCellValue(value, range.row[0], range.column[0]);
          }
          if (bi.val_type === 7) {
            // 固定值
            value = this.yiqivalueshow(bi.val)
            document.getElementById('iframeid').contentWindow.app.setCellValue(value, range.row[0], range.column[0]);
          }
          if (bi.is_push === 1) {
            this.push_value = value
            this.push_area = range
          }
        }
      })
      this.checkpushvalue()

      // this.checkpushvaluelist.map((item) => {
      //   if (item.mark_yiqi === '@实验室温度@') {
      //     this.check_wd = item.value
      //   }
      //   if (item.mark_yiqi === '@实验室湿度@') {
      //     this.check_sd = item.value
      //   }
      //   if (item.mark_yiqi === '@气压@') {
      //     this.check_qy = item.value
      //   }
      // })
    },

    yiqivalueshow(val) {
      let val2 = JSON.parse(val)
      let info = false
      let val3 = ''
      this.item_yiqi_list.map((item) => {
        if (Number(item.id) === Number(val2[0])) {
          info = item
        }
      })
      if (info) {
        switch (Number(val2[1])) {
          case 1:
            val3 = info.name
            break;
          case 2:
            val3 = info.serial_num
            break;
          case 3:
            val3 = info.model
            break;
        }
      }
      return val3
    },


    getjcitemhuanjinglist() {
      // 结果录入：获取检测项目仪器列表
      this.$sa0.post({
        url: this.$api('结果录入：获取检测项目检测环境'),
        data: {id: this.task_check_info.check_item},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.autotest()
            this.jcitemhuanjing_list = response.data.list
            this.check_huanjing = this.jcitemhuanjing_list.map((item) => {
              return {
                'id': item.id,
                'name': item.condition_name,
                'value': '',
              }
            });
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getjcitemyiqilist() {
      // 结果录入：获取检测项目仪器列表
      this.$sa0.post({
        url: this.$api('结果录入：获取检测项目仪器列表'),
        data: {id: this.task_check_info.check_item},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jcitemyiqi_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    checkpushvalue() {
      if (this.auto_check) {
        // 公式：检测结果判定
        this.$sa0.post({
          url: this.$api('公式：检测结果判定'),
          data: {
            jc_item_id: this.task_check_info.check_item,
            bz_value: this.push_value,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.check_data_value = 0
              if (Number(response.data.result_type) === 1) {
                this.check_data_value = 1
              }
              if (Number(response.data.result_type) === 2) {
                this.check_data_value = 2
              }
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    },
    valueround(value, rules) {
      console.log(rules)
      if (rules.rechange === 1) {
        let count_ = ''
        switch (rules.rechange_type) {
          case 1:
            count_ = '1'
            for (let i = 0; i < rules.rechange_number; i++) {
              count_ += '0'
            }
            return Math.floor(Number(value) * Number(count_)) / Number(count_)
          case 2:
            count_ = '1'
            for (let i = 0; i < rules.rechange_number; i++) {
              count_ += '0'
            }
            return Math.round(Number(value) * Number(count_)) / Number(count_)
          case 3:
            return value
          case 4:
            let r = /^([0-9]+\.[0-9]{1}[0|2|4|6|8])5$/g;
            let r1 = /^([0-9]+\.[0-9]{1}[1|3|5|7|9])5$/g;
            let r2 = /^([0-9]+\.[0-9]{2})5[0]?[1-9]*/g;
            if (r.test(value)) {
              value = value.replace(r, "$1");
            } else if (r1.test(value)) {
              value = value.replace(r1, "$16");
            } else if (r2.test(value)) {
              value = value.replace(r2, "$16");
            }
            value = parseFloat(value).toFixed(rules.rechange_number);
            return value;
        }
      } else {
        return value
      }
    },
    getbackdatalist() {
      this.$sa0.post({
        url: this.$api('检测项目模板：获取回填数据列表'),
        data: {id: this.task_check_info.check_item},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.backdata_list = response.data.list
            this.pushvalue()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    autotest() {


      this.getcoursedata()
      // this.readyiqivalue()
    },
    getItemTable() {
      this.$sa0.post({
        url: this.$api('获取检测项目表格信息'),
        data: {
          id: this.task_check_info.check_item,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              this.$sa0.localStorage.set('Item_Table_Data', JSON.parse(response.data.info.content))
              this.url_p.t = response.data.info.id
              this.url_p.x = response.data.info.column
              this.url_p.y = response.data.info.row
              this.drawButtonClick()
            } else {
              layer.msg('请先配置样品模板区域')
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    drawButtonClick(t = false) {
      this.iframe_show = false;
      if (t) this.url_p.t = 0;
      let p = this.url_p
      let p_arr = [];
      for (let i in p) {
        p_arr.push(`${i}=${p[i]}`)
      }
      this.url = '/cclucky.html?' + p_arr.join("&");
      setTimeout(() => {
        this.iframe_show = true;
      })
    },
    getcheckitembacklist() {
      console.log(this.task_check_info)
      this.$sa0.post({
        url: this.$api('检测项目模板：获取回填数据列表'),
        data: {
          id: this.task_check_info.check_item
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.checkmarklist = response.data.list
            let yiqi_select_list = []
            let biaoben_select_list = []
            this.checkmarklist.map((item) => {
              if (item.val_type === 3 || item.val_type === 4) {
                if (yiqi_select_list.indexOf(item.val) === -1) {
                  yiqi_select_list.push(item.val)
                }
                if (biaoben_select_list.indexOf(item.back_index) === -1) {
                  biaoben_select_list.push(item.back_index)
                }
              }
            })
            this.checkmarklistselect = yiqi_select_list
            this.checkbiaobenlistselect = biaoben_select_list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    readyiqivalue() {
      if (this.check_date === '') return layer.msg('请选择日期')
      if (this.jcitemyiqi_choose.length === 0) return layer.msg('请选择仪器')
      if (this.tags.length === 0) return layer.msg('请选择标本号')
      let arr = JSON.parse(JSON.stringify(this.jcitemyiqi_choose))
      // arr.push('@实验室温度@')
      // arr.push('@实验室湿度@')
      // arr.push('@气压@')
      const data = {
        date: this.check_date,
        yiqi_arr: arr,
        biaoben_arr: this.tags,
      }
      console.log(data)
      this.$sa0.post({
        url: this.$api('录入检测过程结果：获取仪器数据'),
        data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            console.log(this.checkpushvaluelist)
            this.checkpushvaluelist = response.data.list
            this.checkpushvaluecount = response.data.count
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    gettaskcheckinfo() {
      this.$sa0.post({
        url: this.$api('录入检测过程结果：获取检测任务数据'),
        data: {
          id: this.info.id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_check_info = response.data.info
            this.getcheckitembacklist()
            this.getjcitemyiqilist()
            this.getjcitemhuanjinglist()
            this.getjjianceitemyiqilist()

          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onClose() {
      this.close()
    },
    savecoursedata() {
      // 结果录入：保存检测过程结果
      this.$sa0.post({
        url: this.$api('结果录入：保存检测过程结果'),
        data: {
          task_check_id: this.task_check_info.id,
          item_id: this.task_check_info.check_item,
          check_date: this.check_date,
          yiqi_mark: JSON.stringify(this.jcitemyiqi_choose),
          bb_mark: JSON.stringify(this.tags),
          check_start_date: this.check_start_date,
          check_end_date: this.check_end_date,
          auto_check: this.auto_check ? 1 : 0,
          check_result: this.check_data_value,
          check_huanjing: JSON.stringify(this.check_huanjing),
          table_path: this.table_path,
          out_value: this.push_value,
          image_arr: JSON.stringify(this.gongshi_image),
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('保存成功')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getcoursedata() {
      // 结果录入：获取检测过程结果
      this.$sa0.post({
        url: this.$api('结果录入：获取检测过程结果'),
        data: {
          task_check_id: this.task_check_info.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              let info = response.data.info;
              this.check_date = info.check_date
              this.jcitemyiqi_choose = JSON.parse(info.yiqi_mark)
              this.tags = JSON.parse(info.bb_mark)
              this.check_start_date = info.check_start_date
              this.check_end_date = info.check_end_date
              this.auto_check = Number(info.auto_check) === 1
              this.check_data_value = info.check_result
              this.gongshi_image = JSON.parse(info.image_arr)
              JSON.parse(info.check_huanjing).map((ii) => {
                this.check_huanjing.map((jj, key) => {
                  if (Number(jj.id) === Number(ii.id)) {
                    this.$set(this.check_huanjing[key], 'value', ii.value)
                  }
                })
              })
              this.table_path = info.table_path
              this.push_value = info.out_value
              this.readyiqivalue()
              if (response.data.info.table_content) {
                this.$sa0.localStorage.set('Item_Table_Data', JSON.parse(response.data.info.table_content))
                this.url_p.t = response.data.info.id
                this.url_p.x = response.data.info.column
                this.url_p.y = response.data.info.row
                this.drawButtonClick()
              } else {
                this.getItemTable()
              }
            } else {
              this.getItemTable()
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    handleClose(removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag);
      console.log(tags);
      this.tags = tags;
    },

    showInput() {
      this.tag_input_show = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.tag_input = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.tag_input;
      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      Object.assign(this, {
        tags,
        tag_input: '',
        tag_input_show: false,
      });
    },
    uploadTable() {
      // 结果录入：上传检测过程表格
      let content = document.getElementById('iframeid').contentWindow.app.getContent()
      this.$sa0.post({
        url: this.$api('结果录入：上传检测过程表格'),
        data: {
          table_content: JSON.stringify(content)
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.table_path = response.data.path
            layer.msg('保存成功')
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <a-drawer title="录入检测过程结果" placement="right" width="100%" :visible="visible" @close="onClose">
      <div>
        <a-row style="padding-bottom: 15px">
          <a-col :span="3">
            <a-space>
              <span>日期</span>
              <a-date-picker v-model="check_date" mode="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            </a-space>
          </a-col>
          <a-col :span="4">
            <a-space>
              <span>选择仪器</span>
              <a-select v-model="jcitemyiqi_choose" mode="multiple" style="width: 200px" option-label-prop="label">
                <a-select-option :value="ii.id" :label="ii.name" v-for="(ii,ik) in jcitemyiqi_list">
                  {{ ii.name }}
                </a-select-option>
              </a-select>
            </a-space>
          </a-col>
          <a-col :span="3">
            <div style="float: left">标本号</div>
            <div style="float: left; margin-left: 10px">
              <template v-for="(tag, index) in tags">
                <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                  <a-tag :key="tag" closable @close="() => handleClose(tag)">
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag v-else :key="tag" closable @close="() => handleClose(tag)">
                  {{ tag }}
                </a-tag>
              </template>
              <a-input v-if="tag_input_show" ref="input" type="text" size="small" :style="{ width: '78px' }"
                       :value="tag_input" @change="handleInputChange" @blur="handleInputConfirm"
                       @keyup.enter="handleInputConfirm"/>
              <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                <a-icon type="plus"/>
                添加标本号
              </a-tag>
            </div>
          </a-col>
          <a-col :span="11">
            <a-space>
              <!--            <a-select-->
              <!--                v-model="checkbiaobenlistchoose"-->
              <!--                mode="multiple"-->
              <!--                style="width: 200px"-->
              <!--                option-label-prop="label"-->
              <!--            >-->
              <!--              <a-select-option :value="ii" :label="ii" v-for="(ii,ik) in checkbiaobenlistselect">-->
              <!--                {{ ii }}-->
              <!--              </a-select-option>-->
              <!--            </a-select>-->
              <a-button @click="readyiqivalue()">读取仪器数据</a-button>
              <a-button @click="getbackdatalist()">填充数据</a-button>
              <a-button>查看曲线</a-button>
              <a-button type="primary" @click="uploadTable()">保存表格内容</a-button>
              <a-button type="primary" @click="savecoursedata()">保存检测过程数据</a-button>
            </a-space>
          </a-col>
        </a-row>

        <a-row style="padding-bottom: 15px">
          <a-col :span="4">
            <a-space>检验开始日期
              <a-date-picker v-model="check_start_date" mode="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            </a-space>
          </a-col>
          <a-col :span="4">
            <a-space>检验结束日期
              <a-date-picker v-model="check_end_date" mode="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            </a-space>
          </a-col>
          <a-col :span="3">
            <a-checkbox v-model="auto_check">录入结果后自动判定是否合格</a-checkbox>
          </a-col>
          <a-col :span="6">
            <a-space>是否合格
              <a-select v-model="check_data_value" style="width: 120px">
                <a-select-option :value="0">未判定</a-select-option>
                <a-select-option :value="1">合格</a-select-option>
                <a-select-option :value="2">不合格</a-select-option>
              </a-select>
            </a-space>
          </a-col>
        </a-row>

        <a-row style="padding-bottom: 15px">
          <a-col :span="2" v-for="(hi,hk) in check_huanjing">
            <a-space>{{ hi.name }}
              <a-input style="width: 100px;" v-model="hi.value"></a-input>
            </a-space>
          </a-col>
        </a-row>

        <!--          <table class="layui-table" lay-size="sm">-->
        <!--            <tbody>-->
        <!--            <tr>-->
        <!--              <td>检验开始日期</td>-->
        <!--              <td>-->
        <!--                <a-date-picker v-model="check_start_date" mode="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>-->
        <!--              </td>-->
        <!--              <td>检验结束日期</td>-->
        <!--              <td>-->
        <!--                <a-date-picker v-model="check_end_date" mode="date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>-->
        <!--              </td>-->
        <!--              <td>-->
        <!--                <a-checkbox v-model="auto_check">录入结果后自动判定是否合格</a-checkbox>-->
        <!--              </td>-->
        <!--              <td>是否合格</td>-->
        <!--              <td>-->
        <!--                <a-select v-model="check_data_value" style="width: 120px">-->
        <!--                  <a-select-option :value="0">未判定</a-select-option>-->
        <!--                  <a-select-option :value="1">合格</a-select-option>-->
        <!--                  <a-select-option :value="2">不合格</a-select-option>-->
        <!--                </a-select>-->
        <!--              </td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--          <div>-->
        <!--            <table class="layui-table" lay-size="sm">-->
        <!--              <tbody>-->
        <!--              <tr>-->
        <!--                <td v-for="(hi,hk) in check_huanjing">-->
        <!--                  {{ hi.name }}-->
        <!--                  <a-input style="width: 100px;" v-model="hi.value"></a-input>-->
        <!--                </td>-->
        <!--              </tr>-->
        <!--              </tbody>-->
        <!--            </table>-->
        <!--          </div>-->


        <div style="width: 100%;height: 250px;">
          <div v-if="iframe_show">
            <iframe ref="iframeRef" id="iframeid" class="luckysheet_wrapper" :src="url" frameborder="0"></iframe>
          </div>
        </div>
        <div>
          <!--          checkpushvaluecount-->

          <div v-if="checkpushvaluelist.length!=0">
            <div class="table">
              <div class="row bg9">
                <div class="cell">标本号</div>
                <div class="cell">样品序号</div>
                <div v-for="(thi,thk) in checkpushvaluecount" class="cell">输出值{{
                    thk + 1
                  }}
                </div>
                <div class="cell">检出日期</div>
              </div>
              <div class="row" v-for="(ci,ck) in checkpushvaluelist">
                <div class="cell" style="position: relative">
                  <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)">
                    {{ ci.yp_number }}
                  </div>

                </div>
                <div class="cell">
                  <div style="opacity: 0">空白</div>
                  <div class="" v-for="(bhi,bhk) in ci.list">
                    <a-input v-model="bhi.mark_bh" class="ypinput" style="width: 60px"></a-input>
                  </div>
                </div>
                <div class="cell" v-for="(thi,thk) in checkpushvaluecount">
                  <div>
                    {{ ci.list[0].list[thk].mark_yiqi }}
                  </div>
                  <div class="" v-for="(bhi,bhk) in ci.list">
                    <div>
                      <a-input v-model="bhi.list[thk].value" class="ypinput"></a-input>
                    </div>
                  </div>
                </div>
                <div class="cell" style="position: relative; width: 160px">
                  <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)">
                    {{ ci.mark_date }}
                  </div>

                </div>
              </div>
            </div>
          </div>


          <!--          <table class="layui-table" v-if="checkpushvaluelist.length!=0">-->
          <!--            <thead>-->
          <!--            <tr>-->
          <!--              <th>样品编号</th>-->
          <!--              <th>样品序号</th>-->
          <!--              <th v-for="(thi,thk) in checkpushvaluecount">-->
          <!--                输出值{{ thk + 1 }}-->
          <!--              </th>-->
          <!--              <th>检出值</th>-->
          <!--              <th>检出日期</th>-->
          <!--            </tr>-->
          <!--            </thead>-->
          <!--            <tbody>-->
          <!--            <tr v-for="(ci,ck) in checkpushvaluelist">-->
          <!--              <td>-->
          <!--                <a-input style="width: 100px;" v-model="ci.mark_bh"></a-input>-->
          <!--              </td>-->
          <!--              <td>{{ ci.yp_number }}</td>-->
          <!--              <td>{{ ci.mark_yiqi }}</td>-->
          <!--              <td>-->
          <!--                <a-input style="width: 130px;" v-model="ci.value"></a-input>-->
          <!--              </td>-->
          <!--              <td>{{ ci.mark_date }}</td>-->
          <!--            </tr>-->
          <!--            </tbody>-->
          <!--          </table>-->
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<style scoped>
.yp_number_wrapper {
  width: 150px;
}

.yp_xuhao_wrapper {
  width: 100px;
}

.value_wrapper {
  width: 180px;
}

.date_wrapper {
  width: 150px;
}

.th_wrapper {
  display: flex;
  margin-top: 20px;
  background: #f9f9f9;
  justify-content: space-around;

}

.tr_wrapper {
  display: flex;
}

.tr_item_wrapper {
  border: #eeeeee 1px solid;
  text-align: center;
  margin-top: -2px;
  margin-left: -2px;
  padding: 10px;
}

.th_item_wrapper {
  border: #eeeeee 1px solid;
  line-height: 40px;
  min-height: 40px;
  text-align: center;
  margin-top: -2px;
  margin-left: -2px;
  padding: 0 5px;
}

.luckysheet_wrapper {
  width: 100%;
  height: 246px;
}


.table {
  display: table;
  border: 1px solid #eeeeee;
  margin: 5px;
  width: 100%;
  align-items: center;
  border-top: none;
  border-left: none;

}

.row {
  display: table-row;
  border: 1px solid #ffffff;
  margin-top: -2px;

}

.row:hover {
  background: #fcfcfc
}

.cell {
  display: table-cell;
  border: 1px solid #eee;
  padding: 5px;
  border-right: none;
  border-bottom: none;
  margin-top: -1px;
  margin-left: -1px;
  text-align: center;

}

.ypinput {
  width: 150px;
  margin-top: 10px
}

.bg9 {
  background: #f9f9f9
}


</style>
