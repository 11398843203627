<template>
  <div class="wraper">
    <div class="wrap-mainer">
      <div class="sidebar">
        <div class="tree_wrapperitem">
          <div class="treetitle">
            <a-tabs v-model="active" @change="callback">
              <a-tab-pane :key="1" tab="待检验"></a-tab-pane>
              <a-tab-pane :key="2" tab="检验完成"></a-tab-pane>
            </a-tabs>
          </div>
          <ltree ref="ltreeref" w="230px" ot="only" :dt="1" :it="2" :data="tree" all="all1" :all_menu="[]" :menu="[]"
                 :callback="treeCallback"></ltree>
        </div>
      </div>
      <div class="wrap-container" style="padding: 0 15px; ">
        <a-row>
          <a-col :span="24">
            <a-form-model layout="inline" v-model="formSearch">
              <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                <a-input v-model="formSearch.name" placeholder="输入检测项目名称" style="width: 200px"
                         @keyup="Get_spec_itempage()"></a-input>
              </a-form-model-item>
              <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                <a-select v-model="formSearch.status" style="width: 200px" @change="Get_spec_itempage()">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="10">等待检测</a-select-option>
                  <a-select-option value="2">待提交校验</a-select-option>
                  <a-select-option value="7">已提交校验</a-select-option>
                  <a-select-option value="9">已提交审核</a-select-option>
                  <a-select-option value="3">校验通过</a-select-option>
                  <a-select-option value="5">审核通过</a-select-option>
                  <a-select-option value="4">校验不通过</a-select-option>
                  <a-select-option value="6">审核不通过</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                <a-button type="danger" @click="Clear()">清空搜索</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
        <a-row>
          <a-space class="btner" style="margin-top: 10px">
            <div class="btnerlist ">
              <i class="iconfont icon-lurubaogao1"></i>直接录入结果
            </div>
            <div class="btnerlist" @click="checklineclick()">
              <i class="iconfont icon-luruzhong" style="vertical-align: -2px"></i>录入检测过程结果
            </div>
            <div class="btnerlist" @click="toyuanshijiludan()">
              <i class="iconfont icon-jurassic_edit-data" style="vertical-align: -2px"></i>编制原始记录单
            </div>
            <div class="btnerlist">
              <i class="iconfont icon-qingsao" style="font-size: 17px; vertical-align: -1px"></i>清除记录值
            </div>
            <div class="btnerlist" @click="setStatusClick()">
              <i class="iconfont icon-xiaoyanzuoye" style="vertical-align: -2px"></i>提交校验
            </div>
            <div class="btnerlist" @click="setStatus('1')">
              <i class="iconfont icon-weibiaoti545"></i>撤销校验申请
            </div>
            <!--                            <div class="btnerlist" >-->
            <!--                                <i class="iconfont icon-jurassic_last"></i>原始记录单-->
            <!--                            </div>-->
            <div class="btnerlist" @click="showDrawerNewItem()">
              <i class="iconfont icon-xiaoyanrizhi" style="font-size: 17px; vertical-align: -2px"></i>检验日志
            </div>
            <div class="btnerlist">
              <i class="iconfont icon-jieshou1" style="font-size: 17px; vertical-align: -2px"></i>申领样品按钮
            </div>
          </a-space>


          <table class="layui-table" lay-size="sm" style="margin-top: -9px">
            <thead>
            <tr>
              <th>
                <a-checkbox v-model="checkAll" @change="allChange"></a-checkbox>
              </th>
              <th @click="paixuclickup(1)" style="cursor: pointer; ">
                <span>样品编号</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 1 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 1 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th @click="paixuclickup(2)" style="cursor: pointer;">
                <span>检验项目</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 2 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 2 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th @click="paixuclickup(3)" style="cursor: pointer;">
                <span>状态</span>
                <div style="display: inline-block; margin-top: -5px">
                             <span class="ant-table-column-sorter">
                           <a-icon type="caret-up" class="jiantou"
                                   :class="[data_name === 3 && data_sort === 'asc' ?'classname':'']"/>
                           <a-icon type="caret-down" class="jiantou"
                                   :class="[data_name === 3 && data_sort === 'desc' ?'classname':'']"
                                   style=" margin-top: -5px"/>
                        </span>
                </div>
              </th>
              <th>检验结果</th>
              <th>校验人</th>
              <th>审核人</th>
              <th>标准值</th>
              <th>单位</th>
              <th>判定结果</th>
              <th>检验标准</th>
              <th>首次完成时间</th>
              <th>最后修改时间</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in Get_spec_item_list" :class="[item.check ? 'active':'']" @click="clickrow(key)">
              <td>
                <a-checkbox v-model="item.check" @change="itemActiveChange"></a-checkbox>
              </td>
              <td>{{ item.in_number }}</td>
              <td>{{ item.item_name }}</td>
              <td v-if="item.is_tuihui === 1" style="color: #ff0000">
                {{ item.state_turn }}
                <div v-if="item.state_turn === '待检测-校验不通过' || item.state_turn === '审核不通过'">
                  （原因：{{ item.remark }}）
                </div>
              </td>
              <td v-else>{{ item.state_turn }}</td>
              <td>{{ item.jc_result }}</td>
              <td>{{ item.jiaoduiren }}</td>
              <td>{{ item.shenheren }}</td>
              <td>{{ item.bz_value_str }}</td>
              <td>{{ item.factor_unit_name }}</td>
              <td>{{ item.pd_result }}</td>
              <td>{{ item.check_bz_name }}</td>
              <td>{{ item.create_time }}</td>
              <td>{{ item.update_time }}</td>
            </tr>
            </tbody>
          </table>
          <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>

        </a-row>
      </div>
      <div v-if="checklineshow && checklineinfo">
        <checklineinput :close="checklineclose" :info="checklineinfo"></checklineinput>
      </div>
      <div v-if="iedit_infoNewItem">
        <TimeLine :edit_infoNewItem="edit_infoNewItem" :close="onCloseDrawerNewItem"
                  :visible="drawer_visibleNewItem"></TimeLine>
      </div>


      <a-modal v-model:visible="choose_jiaoyan" title="确认校验人" @ok="setStatus('2')">
        <div>
          <table class="layui-table" lay-size="sm" style="margin-top: -11px">
            <thead>
            <tr>
              <th>样品编号</th>
              <th>检测项目</th>
              <th>校验人</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,key) in jiance_list" :key="key">
              <td>{{ item.in_number }}</td>
              <td>{{ item.item_name }}</td>
              <td>
                <a-select v-model="item.read_per" class="w-full">
                  <a-select-option :value="0">暂无</a-select-option>
                  <a-select-option v-for="(jyi,jyk) in jiaoyanren_list" :value="jyi.id">
                    {{ jyi.name }}
                  </a-select-option>
                </a-select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import TimeLine from "./time-line/time-line";
import ltree from './ltree/ltree.vue';
import checklineinput from './check_line_input.vue'

export default {
  name: "resultinput",
  components: {ltree, TimeLine, checklineinput},
  data() {
    return {

      jiance_list: [],
      jiaoyanren_list: [],
      task_check: false,
      choose_jiaoyan: false,

      menu_arr: [],
      all_menu: [],
      tree: [],
      method_id: 0,
      checklineshow: false,
      checklineinfo: false,
      active: 1,
      formSearch: {
        name: '',
        status: '',

      },
      Get_spec_item_list: [],
      checkAll: false,
      page: 1,
      count: 0,
      pagesize: 0,
      edit_timeNewItem: [],
      iedit_infoNewItem: false,
      edit_infoNewItem: false,
      edit_infoNewItem_task_id: false,
      drawer_visibleNewItem: false,
      page_show: false,
      spec_item_id: '',
      tree_active_id: [],
      first_son: false,
      tabinfo: '',
      data_name: 0,
      data_sort: 'asc',

    }
  },
  watch: {
    $route: {
      handler() {
        this.onMounted()//列表
      },
      deep: true
    },
    itemActiveChange() {
      let do_ = true
      this.Get_spec_item_list.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.checkAll = do_
    },
    allChange(e) {
      this.Get_spec_item_list.map((item, key) => {
        this.$set(this.Get_spec_item_list[key], 'check', this.checkAll)
      })
    },
  },
  mounted() {
    this.onMounted()//列表
  },
  methods: {
    getJiaoyanrenList() {
      this.$sa0.post({
        url: this.$api('任务:校验人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onMounted() {
      this.getJiaoyanrenList()
      this.checkquery()
      let qid = Number(this.$route.query.id) || false;
      this.active = Number(this.$route.query.type) || 1;
      console.log(qid)
      if (!qid || qid === 0) {
        this.method_id = 0
        this.Get_spec_item()
      }
      this.GMethod_category_alllist(this.active, Number(this.$route.query.id) || 0)
      setTimeout(() => {
        this.tree_show = true
      }, 100);
      // this.callback(1)
    },
    menuarrmaker() {
      this.all_menu = []
    },

    getcoursedata(info) {
      // 结果录入：获取检测过程结果
      this.$sa0.post({
        url: this.$api('结果录入：获取检测过程结果'),
        data: {
          task_check_id: info.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            if (response.data.info) {
              this.$router.push('/startCheck/' + info.id)
            } else {
              layer.msg('请先配置检测结果')
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toyuanshijiludan() {
      let info = false;
      let index = 0;
      this.Get_spec_item_list.map((item) => {
        if (item.check) {
          index++;
          info = item;
        }
      })
      if (index !== 1) {
        if (index == 0) return layer.msg('请选择检测任务')
        return layer.msg('只能选择一个检测任务')
      } else {
        this.getcoursedata(info)
      }
    },
    checkquery() {
      let id = this.$route.query.id
      if (id) this.treeCallback1(id)
    },
    checklineclose() {
      this.checklineshow = false
      this.checklineinfo = false
    },
    checklineclick() {
      let info = false;
      let index = 0;
      this.Get_spec_item_list.map((item) => {
        if (item.check) {
          index++;
          info = item;
        }
      })
      if (index !== 1) {
        if (index == 0) return layer.msg('请选择检测任务')
        return layer.msg('只能选择一个检测任务')
      } else {
        this.checklineinfo = info
        this.checklineshow = true
      }
    },
    clickrow(key) {
      this.$set(this.Get_spec_item_list[key], 'check', !this.Get_spec_item_list[key].check)
      this.itemActiveChange()
    },
    itemActiveChange() {
      let do_ = true
      this.Get_spec_item_list.map((item, key) => {
        if (!item.check) do_ = false
      })
      this.checkAll = do_
    },
    allChange(e) {
      this.Get_spec_item_list.map((item, key) => {
        this.$set(this.Get_spec_item_list[key], 'check', this.checkAll)
      })
    },
    callback() {

      this.$router.push({
        name: this.$route.name,
        query: {id: this.tabinfo.id, type: this.active}
      })

    },
    setStatusClick() {
      this.jiance_list = []
      this.Get_spec_item_list.map((item) => {
        if (item.check) {
          this.jiance_list.push(item)
        }
      })
      this.choose_jiaoyan = true
    },
    setStatus(iNumber) {
      let source_record_id = []
      let jc_item_id = []
      let jiaoyanren = []
      //let state_turn = []
      // let msg = ''
      this.Get_spec_item_list.map((item) => {
        if (item.check) {
          source_record_id.push(Number(item.source_record_id))
          jc_item_id.push(Number(item.jc_item_id))
          jiaoyanren.push(Number(item.read_per))
          // if (item.state_turn === '待检测-校验不通过') {
          //     msg = '请重新检测并修改原始记录单'
          // }
          //state_turn.push(Number(item.state_turn))
        }
      })
      for (let i in jiaoyanren) {
        if (Number(jiaoyanren[i]) === 0) {
          layer.msg('请选择检验人')
          return
        }
      }
      //console.log("6666666666666666666666666666666666666")
      //console.log(source_record_id)
      if (source_record_id.length < 1) {
        layer.msg('请勾选需要操作的检测项目')
        return
      }
      // if (msg !== "") {
      //     layer.msg(msg)
      //     return
      // }
      this.$sa0.post({
        url: this.$api('Set_source_record_all'),
        data: {
          state: iNumber,
          source_record_id: source_record_id.join(','),
          jc_item_id: jc_item_id.join(','),
          jiaoyanren: jiaoyanren.join(','),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.choose_jiaoyan = false
            layer.msg(response.message)
            this.Get_spec_item()
            // this.Get_source_record_log()
          },
          error: (response) => {
            layer.msg(response.message)
          },

        })
      })
    },
    Clear() {
      this.formSearch = {
        name: '',
        status: '',
      }
      this.Get_spec_item();
    },
    onChange(page) {
      this.page = page;
      this.Get_spec_item();
    },
    autotest() {
      // this.Get_spec_item_list[0].check = true
      // this.checklineclick()
    },
    Get_spec_itempage() {
      this.page = 1
      this.Get_spec_item()
    },
    Get_spec_item() {
      this.$sa0.post({
        url: this.$api('Get_spec_item'),
        data: {
          spec_item_id: this.method_id,
          item_name: this.formSearch.name,
          state: this.formSearch.status,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            //this.Get_spec_item_list = response.data.result.list;

            this.Get_spec_item_list = response.data.result.map((item) => {
              item.check = false
              return item;
            });
            this.autotest()
            //console.log("================================================================");
            //console.log(this.Get_spec_item_list);
            this.count = Number(response.data.result.count);//分页
            this.pagesize = Number(response.data.result.pagesize);//分页
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    paixuclickup(ordertype) {
      if (this.data_name !== ordertype) {
        this.data_name = ordertype
        this.data_sort = 'asc'
      } else {
        if (this.data_sort === 'asc') {
          this.data_sort = 'desc'
        } else {
          this.data_sort = 'asc'
        }
      }
      this.$sa0.post({
        url: this.$api('Get_spec_item'),
        data: {
          spec_item_id: this.method_id,
          item_name: this.formSearch.name,
          state: this.formSearch.status,
          data_name: this.data_name,
          data_sort: this.data_sort,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.Get_spec_item_list = response.data.result
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },

    //tree开始
    // 获取树列表
    GMethod_category_alllist(active, id) {
      //console.log("=====================================================")
      this.$sa0.post({
        url: this.$api('Get_task'),
        data: {
          state: active,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tree = this.makeList(response.data.task);

            if (id === 0 && this.first_son) {
              this.$router.push({
                name: this.$route.name,
                query: {id: this.first_son.id, type: this.active}
              })
              return
            }

            if (id !== 0) {
              setTimeout(() => {
                this.$refs['ltreeref'].renameActive('no')
              }, 10)
            }


            this.tree_show = true
            setTimeout(() => {
              this.$refs['ltreeref'].openGroup(this.tree_active_id)
            }, 100)
            this.menuarrmaker()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    makeList(task, pid = []) {
      if (!task) return []
      let qid = Number(this.$route.query.id) || 0;
      return task.map((item) => {
        if (qid && qid === Number(item.id)) {
          this.tree_active_id = [...pid, item.id]
          this.deep_info = item
          this.item_show = false
          setTimeout(() => {
            this.item_show = true
            setTimeout(() => {
              this.getMethodInfo(item.id)
            })
          })
        }
        if (!this.first_son && item.type === 2) {
          this.first_son = item;
        }
        return {
          'id': Number(item.id),
          "type": item.type,
          "name": item.name,
          "children": item.spec_item && item.spec_item.length !== 0 ? this.makeList(item.spec_item, [...pid, item.id]) : [],
          "content": {
            "pids": pid,
            "pid": item.pid,
          }
        }
      })
    },
    treeCallback1(id) {
      this.getMethodInfo(id)
    },
    treeCallback(item) {
      this.tabinfo = item[item.length - 1]
      let info = item[item.length - 1]
      if (info.type === 1) return
      this.$router.push({
        name: this.$route.name,
        query: {id: info.id, type: this.active}
      })
    },


    getMethodInfo(id) {
      this.method_id = id === 'all' ? '' : id
      // 录入检测过程结果：获取检测任务信息
      this.$sa0.post({
        url: this.$api('录入检测过程结果：获取检测任务信息'),
        data: {
          id: this.$route.query.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.$refs.ltreeref.openGroup([Number(response.data.info.task_id), Number(this.$route.query.id)])
            console.log(this.$refs.ltreeref.active_arr)
            console.log(this.$refs.ltreeref.active_arr)
            console.log(this.$refs.ltreeref.active_arr)
            console.log(this.$refs.ltreeref.active_arr)
            console.log(this.$refs.ltreeref.active_arr)
            this.Get_spec_item()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })

    },
    showDrawerNewItem() {
      let id = 0
      let count = 0
      this.Get_spec_item_list.map((item, key) => {
        if (item.check) {
          this.edit_infoNewItem = item
          id = item.source_record_id
          count++
        }
        //console.log(123, this.edit_infoNewItem)
      })
      if (!id) return layer.msg('请选择一个检验项目查看检验日志')
      if (count !== 1) return layer.msg('只能选择一个检验项目')
      //console.log(987, this.edit_infoNewItem)
      this.iedit_infoNewItem = false
      setTimeout(() => {
        this.edit_timeNewItem = new Date() / 1;
        this.drawer_visibleNewItem = true;
        this.iedit_infoNewItem = true;
      })
    },
    // 定义 关闭抽屉时的
    onCloseDrawerNewItem() {
      this.drawer_visibleNewItem = false;
    },

  }
}
</script>

<style scoped>

/*******************按钮区开始*********************
***********************************************/
.btner {
  width: 100%;
  background: #f3f3f3;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #e9e8e8;
  height: 26px;
  line-height: 24px;
  padding: 0 12px
}

.btner:last-child {
  border: none
}

.isthis, .btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
  height: 26px;
  line-height: 24px;
}

.btnerlist i {
  color: #999999;
  font-size: 16px;
  margin-right: 3px;
}

.isthis i, .btnerlist:hover i {
  color: #ffffff !important
}

/*******************树开始*********************
*********************************************/
.tree_wrapperitem {
  position: fixed;
  height: 100%;
  overflow-y: auto;
  margin-left: 0px;
  border: 1px solid #eeeeee;
}

.treetitle {
  width: 230px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
}

/*******************框架开始*********************
***********************************************/
.wraper {
  width: 100%;
  height: 100%;
  display: flex;
}

.wrap-mainer {
  flex: 1;
  display: flex;
}

.sidebar {
  width: 230px;
  display: flex;
  flex-direction: column
}

.wrap-container {
  flex: 1;
  overflow-y: auto;
}

table tr {
  cursor: pointer
}


.btner {
  width: 100%;
  background: #f9f9f9;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
}

.active {
  background: #96cbf9;
}

.btnerlist {
  cursor: pointer;
  border-right: 1px solid #eeeeee;
  height: 26px;
  line-height: 26px;
  padding: 0 10px
}

.btnerlist :last-child(1) {
  border: none
}

.fcadd {
  color: #66c14c !important;
  font-size: 18px !important;
}

.btnerlist i {
  color: #999999;
  font-size: 14px;
  vertical-align: bottom;
}

.btnerlist:hover {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;

}

.btnerlist:hover i, .btnerlist:hover.fcadd {
  color: #ffffff !important
}

.isthis {
  background: #1592fe;
  color: #ffffff;
  border-radius: 4px;
}

.isthis i {
  color: #ffffff
}

.active {
  background: #ebf6ff
}

.ant-table-column-sorter {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 5px;
  cursor: pointer;
  width: 10px;
  vertical-align: middle;
}

.jiantou {
  color: #999999
}

.classname {
  color: #1890ff;
}

.fl {
  float: left
}

.active {
  background: #ebf6ff
}

table tr {
  cursor: pointer
}
</style>
