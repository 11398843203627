<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-03-10 17:57:54
     */
    import ltreemenu from './ltree_menu.vue'

    export default {
        components: {ltreemenu},
        name: 'ltreeitem',
        props: {
            w: {
                type: String,
                default: '400px'
            },
            // 点击回馈
            feedback: {
                type: Function,
                default: () => {
                }
            },
            renameDo: {
                type: Function,
                default: () => {
                }
            },
            // 层级
            deep: {
                type: Number,
                default: 0
            },
            // 重命名激活
            rename_active: {
                type: Number | String,
                default: ''
            },
            // 菜单按钮
            menu: {
                type: Array,
                default: []
            },
            // 现在激活的列表
            active: {
                type: Array,
                default: []
            },
            // 单位信息
            info: {
                type: Object,
                default: null
            },
            // 文件夹类型
            dt: {
                type: Number,
                default: 0
            },
            // 内容类型
            it: {
                type: Number,
                default: 1
            },
            // 打开类型 all-不限制 only-只能打开一个
            ot: {
                type: String,
                default: 'all'
            }
        },
        watch: {
            rename_active(val) {
                if (val === this.info.id) {
                    this.renameactive = true
                    this.rename_input = this.info.name
                    setTimeout(() => {
                        this.$refs[`inputref_${this.info.id}`].focus()
                    })
                }
            },
            // active: {
            //     handler() {
            //         if (this.ot === 'only') {
            //             if (this.info.id !== this.active[this.deep]) {
            //                 this.child_show = false
            //             }
            //         }
            //     },
            //     deep: true
            // }

            active: {
                handler(val, oal) {
                    if (oal.length === 0) {
                        this.checkActive(true)
                    } else {
                        this.checkActive(false)
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                child_show: false,
                renameactive: false,
                rename_input: '',
            }
        },
        mounted() {
            this.checkActive(true)
        },
        methods: {
            checkActive(first_) {
                if (this.ot === 'only') {
                    if (this.info.id !== this.active[this.deep]) {
                        this.child_show = false
                    } else {
                        if (first_) this.child_show = true
                    }
                }
            },
            renameBlur() {
                this.renameDo({
                    id: this.info.id,
                    name: this.info.name,
                    type: this.info.type,
                    content: this.info.content
                }, this.rename_input)
                this.renameactive = false
            },
            itemFeedback(arr) {
                arr[this.deep] = {
                    id: this.info.id,
                    name: this.info.name,
                    type: this.info.type,
                    content: this.info.content
                }
                this.feedback(arr)
            },
            itemTitleClick() {
                if (this.info.type === this.dt) {
                    this.child_show = !this.child_show
                }
                let deep_arr = []
                for (let i = 0; i < this.deep + 1; i++) {
                    deep_arr.push([])
                }
                deep_arr[this.deep] = {
                    id: this.info.id,
                    name: this.info.name,
                    type: this.info.type,
                    content: this.info.content
                }
                this.feedback(deep_arr)
            }
        }
    }
</script>
<template>
    <div>
        <div v-if="info">
            <div class="group_wrapper">
                <div class="group_title_wrapper" :class="(active.length && active[active.length - 1] === info.id)?'group_active':''">
                    <div class="group_bg_wrapper" :style="{
            width:w
          }"></div>
                    <div class="more_icon_wrapper">
                        <div class="menu_wrapper">
                            <ltreemenu :menu="menu" :info="info"></ltreemenu>
                        </div>
                        <!--                        <div class="czs-more"></div>-->
                    </div>
                    <div class="group_title_wrapper" @click="itemTitleClick()">
                        <a-space>
                            <!--                            <div class="dir_open_icon_wrapper">-->
                            <!--                                <div :class="child_show?'dir_open':'dir_close'" v-if="info.type === 0">-->
                            <!--                                    <a-icon type="caret-right"/>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="item_icon_wrapper">
                                <a-icon v-if="info.type === dt" :type="child_show?'folder-open':'folder'" theme="filled" style="vertical-align: middle;font-size: 14px; color: #666"/>
                                <a-icon v-else type="file-text" style="vertical-align: middle"/>
                            </div>
                            <div v-if="!renameactive" class="group_name_wrapper">{{ info.name }}</div>
                            <div v-show="renameactive" class="group_name_wrapper">
                                <a-input :ref="`inputref_${info.id}`" @blur="renameBlur()" v-model="rename_input"></a-input>
                            </div>
                        </a-space>
                    </div>
                </div>
                <div v-if="child_show" class="child_wrapper" :style="{
        marginLeft: `${10*(deep+1)}px`
        }">
                    <ltreeitem :ot="ot" :dt="dt" :w="w" :menu="menu" :active="active" :rename_active="rename_active" :feedback="itemFeedback" :it="it" :info="gi" :deep="deep + 1" v-for="(gi,gk) in info.children" :renameDo="renameDo"></ltreeitem>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .menu_wrapper{
        display: none;
        }

    .more_icon_wrapper:hover .menu_wrapper{
        display: block;
        }

    .more_icon_wrapper .czs-more{
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 20px;
        transform: rotate(90deg) translateX(-50%);
        z-index: 1;
        display: none;
        }

    .group_active .group_bg_wrapper{
        position: absolute;
        height: 36px;
        right: 0;
        background: #c9e3ff;
        z-index: -1;
        top: 0px;
        width: 100%;
        }

    .group_title_wrapper{ width: 100%; height: 36px; line-height: 36px; vertical-align: middle; padding: 0;}

    .group_title_wrapper:hover .more_icon_wrapper .czs-more{
        display: block;
        }

    .group_title_wrapper{
        position: relative;
        cursor: pointer;
        height: 36px;
        line-height: 36px;
        }

    .group_name_wrapper{
        user-select: none;
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        }

    .item_icon_wrapper{
        font-size: 16px;
        }

    .dir_open_icon_wrapper{
        font-size: 12px;
        margin-top: -1px;
        }

    .group_wrapper{
        min-height: 36px;
        line-height: 36px;
        }

    .dir_close{
        transform: rotate(0deg);
        vertical-align: middle;
        margin-top: 0px;
        width: 10px;
        }

    .dir_open{
        transform: rotate(90deg);
        vertical-align: middle;
        margin-left: 6px; margin-top: 1px;
        width: 4px;
        text-align: right;
        }

    .ant-space-align-center{ align-items: normal}
</style>
