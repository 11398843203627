<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="检验日志" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>

                <a-timeline>
                    <!--          color="orange"color="red"-->
                    <a-timeline-item v-for="(item,key) in Get_source_record_log_list.list" :color="colorStatus(item)">
                        <p>{{item.title}}</p>
                        <p>
                            <a-space>
                                <span>处理人：{{item.danda_name}}</span>
                            </a-space>
                        </p>
                        <p v-if="item.title == '校验不通过' || item.title == '撤销校验' || item.title == '审核不通过'">
                            原因：{{item.remark}}</p>
                        <p>
                            <a-space>
                                <span>处理时间：{{item.create_time}}</span>
                            </a-space>
                        </p>


                    </a-timeline-item>

                    <a-timeline-item color="green" v-if="Get_source_record_log_list.source_record_create">
                        <p>{{Get_source_record_log_list.source_record_create.title}}</p>
                        <p>
                            <a-space>
                                <span>创建人：{{Get_source_record_log_list.source_record_create.danda_name}}</span>
                            </a-space>
                        </p>
                        <p>
                            <a-space>
                                <span>创建时间：{{Get_source_record_log_list.source_record_create.create_time}}</span>
                            </a-space>
                        </p>
                    </a-timeline-item>

                </a-timeline>

            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_infoNewItem'],
        data() {
            return {
                Get_source_record_log_list: [],
            }
        },
        mounted() {
            //console.log("==============================================================")
            //console.log(this.edit_infoNewItem)
            this.Get_source_record_log()
        },
        methods: {
            colorStatus(item) {
                if (item.title === '校验不通过' || item.title === '审核不通过') return 'red'
                if (item.title === '撤销校验') return 'orange'
                return 'green'
            },
            Get_source_record_log() {
                this.$sa0.post({
                    url: this.$api('Get_source_record_log'),
                    data: {
                        source_record_id: this.edit_infoNewItem.source_record_id,
                        task_id: this.edit_infoNewItem.task_id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_source_record_log_list = response.data;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>
<style scoped>

</style>
